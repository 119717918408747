<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('batchCode')"
            label-for="batchCode"
          >
            <b-form-input
              v-model="optionsLocal.batchCode"
              :placeholder="$t('batchCode')"
              name="batchCode"
              disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              :label="$t('batchName')"
            label-for="batchName"
          >
            <b-form-input
              v-model="optionsLocal.batchName"
              name="batchName"
              :placeholder="$t('batchName')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('batchDescription')"
            label-for="batchDescription"
          >
            <b-form-input
              v-model="optionsLocal.batchDescription"
              name="batchDescription"
              :placeholder="$t('batchDescription')"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            :label="$t('batchQuantity')"
            label-for="batchQuantity"
          >
            <b-form-input
              v-model="optionsLocal.batchQuantity"
              name="batchQuantity"
              :placeholder="$t('batchQuantity')"
            />
          </b-form-group>
        </b-col>

        <!-- start date -->
        <b-col md="6">
          <b-form-group
              label-for="start_date"
              :label="$t('start_date')"
          >
            <flat-pickr
                v-model="optionsLocal.start_date"
                class="form-control"
                name="start_date"
                :placeholder="$t('start_date')"
            />
          </b-form-group>
        </b-col>

        <!-- end date -->
        <b-col md="6">
          <b-form-group
              label-for="end_date"
              :label="$t('end_date')"
          >
            <flat-pickr
                v-model="optionsLocal.end_date"
                class="form-control"
                name="end_date"
                :placeholder="$t('end_date')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('batchStatus')"
              label-for="batchStatus"
          >
            <b-form-input
                v-model="optionsLocal.batchStatus"
                name="batchStatus"
                :placeholder="$t('batchStatus')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('percentage')"
              label-for="percentage"
          >
            <b-form-input
                v-model="optionsLocal.percentage"
                name="percentage"
                :placeholder="$t('percentage')"
            />
          </b-form-group>
        </b-col>


        <b-col sm="6">
          <b-form-group
              :label="$t('actualManager')"
              label-for="optionsLocal.actualManager"
          >
            <validation-provider
                #default="{ errors }"
                name="actualManager"
                rules="required"
            >
              <b-form-select
                  id="register-actualManager"
                  :state="errors.length > 0 ? false:null"
                  v-model="optionsLocal.actualManager"
                  name="actualManager"
                  :options="options"
                  :placeholder="$t('optionsLocal.actualManager')"
                  :selected = "optionsLocal.actualManager"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="updateForm"
          >
            Save changes
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {
    BFormSelect,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    useJwt
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    options:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    updateForm() {
      useJwt.putData("tracking/batch/create/?status=update&part=general", {
        batchCode: this.optionsLocal.batchCode,
        batchName: this.optionsLocal.batchName,
        batchDescription: this.optionsLocal.batchDescription,
        batchQuantity: this.optionsLocal.batchQuantity,
        start_date: this.optionsLocal.start_date,
        end_date: this.optionsLocal.end_date,
        batchStatus: this.optionsLocal.batchStatus,
        percentage: this.optionsLocal.percentage,
        actualManager: this.optionsLocal.actualManager
      }).then(response => {
        window.location.reload();
      })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    const { t } = useI18nUtils()
    return {
      t,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
