<template>
  <b-card>
    <!--/ media -->
    <b-form class="mt-2">
      <b-row>
        <!-- end date -->
        <b-col md="6">
          <b-form-group
              label-for="batchImportTime"
              :label="$t('batchImportTime')"
          >
            <flat-pickr
                v-model="optionsLocal.batchImportTime"
                class="form-control"
                name="batchImportTime"
                :placeholder="$t('batchImportTime')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              :label="$t('batchImportPerson')"
              label-for="optionsLocal.batchImportPerson"
          >
            <validation-provider
                #default="{ errors }"
                name="batchImportPerson"
                rules="required"
            >
              <b-form-select
                  id="register-batchImportPerson"
                  :state="errors.length > 0 ? false:null"
                  v-model="optionsLocal.batchImportPerson"
                  name="batchImportPerson"
                  :options="options"
                  :placeholder="$t('optionsLocal.batchImportPerson')"
                  :selected = "optionsLocal.batchImportPerson"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              :label="$t('batchImportCertificate')"
              label-for="batchImportCertificate"
          >
            <b-form-input
                v-model="optionsLocal.batchImportCertificate"
                name="batchImportCertificate"
                :placeholder="$t('batchImportCertificate')"
            />
          </b-form-group>
        </b-col>
        <b-media no-body>
          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
                ref="refInputEl"
                v-model="profileFile"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="inputImageRenderer"
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
            >
              Reset
            </b-button>
            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
          </b-media-body>
        </b-media>
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="updateForm"
          >
            Save changes
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormSelect,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    useJwt
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    options:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    updateForm() {
      useJwt.putData("tracking/batch/create/?status=update&part=batchimportinfo", {
        batchCode: this.optionsLocal.batchCode,
        batchImportPerson: this.optionsLocal.batchImportPerson,
        batchImportTime: this.optionsLocal.batchImportTime,
        batchImportCertificate: this.optionsLocal.batchImportCertificate,
        actualManager: this.optionsLocal.batchImportPerson,
      }).then(response => {
        window.location.reload();
      })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    const { t } = useI18nUtils()
    return {
      t,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
