<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <BatchCreateGeneral
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options="options"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('BatchCollectionInfo') }}</span>
      </template>

      <BatchCollectionInfo
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options="options"
      />
    </b-tab>

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('BatchProcessInfo') }}</span>
      </template>

      <BatchProcessInfo
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options="options"
      />
    </b-tab>

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('BatchDepartureInfo') }}</span>
      </template>

      <BatchDepartureInfo
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options="options"
      />
    </b-tab>

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('BatchExportInfo') }}</span>
      </template>

      <BatchExportInfo
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options="options"
      />
    </b-tab>

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('BatchImportInfo') }}</span>
      </template>

      <BatchImportInfo
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options="options"
      />
    </b-tab>

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('BatchArrivalInfo') }}</span>
      </template>

      <BatchArrivalInfo
        v-if="batchData.batchCode"
        :general-data="batchData"
        :options = "options"
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import BatchDepartureInfo from './BatchDepartureInfo.vue'
import BatchExportInfo from './BatchExportInfo'
import BatchImportInfo from '@/views/apps/batch/batch-edit/BatchImportInfo'
import BatchArrivalInfo from '@/views/apps/batch/batch-edit/BatchArrivalInfo'
import useJwt from '@/auth/jwt/useJwt'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import router from '@/router'
import BatchProcessInfo from './BatchProcessInfo.vue'
import BatchCollectionInfo from './BatchCollectionInfo.vue'
import BatchCreateGeneral from './BatchCreateGeneral.vue'

export default {
  components: {
    BTabs,
    BTab,
    BatchCreateGeneral,
    BatchCollectionInfo,
    BatchProcessInfo,
    BatchDepartureInfo,
    BatchExportInfo,
    BatchImportInfo,
    BatchArrivalInfo,
  },
  data() {
    return {
      batchData: { },
      options: { }
    }
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  async beforeCreate() {
    await useJwt.getData(`tracking/batch/detail/${router.currentRoute.params.id}/`).then(response => {
      this.batchData = response.data
      useJwt.getData("tracking/batch/create/?status=creating").then(response => {
        this.options = response.data
      })
    })
  },
}
</script>
